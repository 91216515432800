<template>
  <div>
    <WeSearchInput
      class="mb-0"
      name="district"
      v-bind:placeholder="$t('searchDistrict')"
      result-prop="name_city"
      v-on:on-select="onSelect"
    />

    <template v-if="computedData && computedData.length">
      <div class="mt-3">
        <WeCard
          v-for="(city, cityIndex) in getCityData"
          v-bind:key="city.id"
          v-bind:class="[cityIndex !== getCityData.length ? 'mb-2' : 'mb-0']"
        >
          <!-- City Name -->
          <h6 class="font-14 mb-0">{{ city.name }}</h6>
          <!-- ./City Name -->

          <!-- Districts -->
          <div class="row mt-3">
            <div
              class="col-auto"
              v-for="district in city.districts"
              v-bind:key="district.id"
            >
              <button
                class="btn btn-sm btn-light font-12 m-1"
                v-on:click="onRemove(district.id)"
              >
                {{ district.name }} <i class="fas fa-times ml-2"></i>
              </button>
            </div>
          </div>
          <!-- ./Districts -->
        </WeCard>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "CargoDeliveryZone",
  props: {
    data: {
      default: () => {},
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    onSelect(data) {
      this.computedData.push(data);
    },
    onRemove(districtId) {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: this.$t("cancel"),
        confirmButtonText: this.$t("confirm"),
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const districtIndex = this.computedData.findIndex(
            (cd) => cd.id == districtId
          );

          if (districtIndex >= 0) {
            this.computedData.splice(districtIndex, 1);
          }
        }
      });
    },
  },
  computed: {
    ...mapState({
      region: (state) => state.address,
    }),
    computedData: {
      get() {
        return this.data;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    getCityData() {
      let result = [];

      if (this.computedData && this.computedData.length) {
        this.computedData.forEach((district) => {
          let cityData = {};

          const city = district.city;
          const districtData = {};
          districtData.id = district.id;
          districtData.name = district.name;

          const existingCity = result.find((ec) => ec.id == city.id);

          if (existingCity) {
            cityData = existingCity;
            cityData.districts.push(districtData);
          } else {
            cityData.id = city.id;
            cityData.name = city.name;
            cityData.districts = [districtData];
            result.push(cityData);
          }
        });
      }

      return result;
    },
  },
};
</script>
<style lang="scss">
.select__multiple {
  min-height: 200px !important;
}
</style>