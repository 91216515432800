<template>
  <WeContainer card="" v-if="ready">
    <!-- Header -->
    <WeCard class="shadow-sm sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col-12 col-md text-center text-md-left">
          <h5 class="custom-card-title">{{ getTitle }}</h5>
        </div>
        <div class="col-auto mx-auto mt-2 mt-sm-0">
          <!-- Submit -->
          <WeSubmitButton
            parent-class="text-center"
            v-bind:submit-status="submit"
            v-bind:update="$route.params.id"
            v-bind:permission="checkPermission"
            v-on:submit="onSaveForm"
          />
          <!-- ./Submit -->
        </div>
      </div>
    </WeCard>
    <!-- ./Header -->

    <div class="row">
      <!-- Left Panel -->
      <div class="col-12 mb-3 col-lg-8 mb-lg-0">
        <!-- Informations -->
        <WeCard class="mb-3">
          <h5 class="custom-card-title">
            <i class="fas fa-clipboard-list"></i> Firma Bilgileri
          </h5>
          <hr />

          <div class="form-row">
            <div class="col">
              <!-- Name -->
              <WeInput
                name="name"
                label="Kargo Adı"
                v-model="data.name"
                placeholder="Kargo Adı"
                v-bind:required="true"
                v-bind:error="$v.cargoName.$error"
              />
              <!-- ./Name -->
            </div>
            <div class="col">
              <label class="custom-label">Para Birimi</label>
              <WeSelect
                placeholder="Para Birimi"
                :options="shared.currencyCodes.currencies"
                v-model="data.currency_code"
              />
            </div>
          </div>

          <!-- Cargo Company -->
          <div class="form-group">
            <label for="cargo-company" class="custom-label"
              >Kargo Şirketi</label
            >
            <v-select
              label="name"
              v-bind:options="cargoCompanyList"
              v-model="data.cargo_company_id"
              v-bind:reduce="(cargo) => cargo.id"
              v-on:input="getCargoConfigs"
              v-bind:clearable="false"
            ></v-select>
          </div>
          <!-- ./Cargo Company -->

          <!-- Cargo Insurance Company -->
          <div class="form-group">
            <label for="cargo-company" class="custom-label"
              >Kargo Sigorta Şirketi</label
            >
            <v-select
              label="name"
              v-bind:options="shared.cargoCompanies.insuranceList"
              v-model="data.cargo_insurance"
              v-bind:clearable="true"
            >
              <span slot="no-options">Gösterilecek sonuç yok</span>
            </v-select>
          </div>
          <!-- ./Cargo Insurance Company -->
        </WeCard>
        <!-- ./Informations -->

        <!-- Delivery Zone -->
        <WeCard class="mb-3" title="Teslimat Bölgesi">
          <h5 class="custom-card-title">
            <i class="fas fa-globe mr-2"></i> Teslimat Bölgesi
          </h5>
          <hr />
          <CargoDeliveryZone v-model="data.delivery_zones" />
        </WeCard>
        <!-- ./Delivery Zone -->

        <!-- Cash On Delivery -->
        <WeCard v-if="cashDeliverySetting">
          <h5 class="custom-card-title">
            <i class="fas fa-money-bill mr-2"></i> Kapıda Ödeme
          </h5>
          <hr />

          <div class="row">
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <!-- Price -->
              <WePriceInput
                v-bind:prepend="data.currency_code.symbol"
                v-model="data.direct_payment.min"
                tooltip="Sepet tutarı belirtilen tutarı geçtiğinde kapıda ödeme seçilebilir."
                label="Minimum Kullanım Limiti"
                ref="directPaymentMin"
              />
              <!-- ./Price -->
            </div>
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <!-- Price -->
              <WePriceInput
                v-bind:prepend="data.currency_code.symbol"
                v-model="data.direct_payment.max"
                tooltip="Sepet tutarı belirtilen tutara ulaştığında kapıda ödeme seçilemez."
                label="Maksimum Kullanım Limiti"
                ref="directPaymentMax"
              />
              <!-- ./Price -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <WeSettingCard
                name="direct-payment"
                v-model="data.direct_payment.cash"
                title="Kapıda Nakit Ödeme"
              />
            </div>
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <!-- Price -->
              <WePriceInput
                v-bind:prepend="data.currency_code.symbol"
                v-model="data.direct_payment.cash_price"
                v-bind:disabled="!data.direct_payment.cash"
                label="Kapıda Nakit Ödeme Fiyatı"
                ref="directPaymentCash"
              />
              <!-- ./Price -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <WeSettingCard
                name="credit-card-payment"
                v-model="data.direct_payment.credit_card"
                title="Kapıda Kredi Kartı Ödeme"
              />
            </div>
            <div class="col-12 mb-3 col-lg mb-lg-0">
              <!-- Price -->
              <WePriceInput
                v-bind:prepend="data.currency_code.symbol"
                v-model="data.direct_payment.credit_card_price"
                v-bind:disabled="!data.direct_payment.credit_card"
                label="Kapıda Kredi Kartı Ödeme Fiyatı"
                ref="directPaymentCreditCard"
              />
              <!-- ./Price -->
            </div>
          </div>
        </WeCard>
        <!-- ./Cash On Delivery -->
        <WeCard v-else class="alert alert-primary">
          <i class="fas fa-info-circle"></i> Kapıda ödeme sistemini
          kullanabilmek için
          <router-link to="/settings" class="btn btn-sm btn-light mx-2"
            ><i class="fas fa-external-link-alt"></i> Ayarlar
            <i class="fas fa-chevron-right"></i> Genel Ayarlar
          </router-link>
          bölümünden <b>Kapıda Ödeme Kullan</b> durumunu aktif hale
          getirmelisiniz.
        </WeCard>
      </div>
      <!-- ./Left Panel -->

      <!-- Right Panel -->
      <div class="col-12 mb-3 col-lg mb-lg-0">
        <WeCard v-bind:class="{ 'mb-3': cargoConfig }">
          <h5 class="custom-card-title">
            <i class="fas fa-percentage"></i> Kargo Oranları
          </h5>
          <hr />

          <!-- Is Free -->
          <div class="row align-items-center mb-3">
            <div class="col">
              <span class="font-weight-bold"> Ücretsiz Kargo </span>
            </div>
            <div class="col-auto">
              <WeSwitch name="is-free" v-model="data.is_free" />
            </div>
          </div>
          <!-- ./Is Free -->

          <!-- Fixed Price -->
          <WePriceInput
            name="fixed-price"
            label="Sabit Ücret"
            v-bind:prepend="data.currency_code.symbol"
            v-model="data.rates.fixed_price"
            v-bind:disabled="data.is_free == 1"
            ref="fixedPrice"
          />
          <!-- ./Fixed Price -->
          <!-- Free Cargo -->
          <WePriceInput
            name="free-cargo"
            label="Ücretsiz Kargo"
            v-bind:prepend="data.currency_code.symbol"
            tooltip="Sepet toplam tutarı belirlenen limiti geçtiğinde kargo ücretsiz olacaktır."
            tooltip-position="right"
            v-model="data.rates.min_free_shipping"
            v-bind:disabled="data.is_free == 1"
            ref="freeShipping"
          />
          <!-- ./Free Cargo -->

          <!-- Desi Limit -->
          <WeInput
            name="free-desi-limit"
            label="Ücretsiz Desi Limit"
            tooltip="Ücretsiz kargo miktarı için maksimum desi miktarıdır."
            tooltip-position="right"
            v-model="data.rates.max_desi_limit"
            v-bind:disabled="data.is_free == 1"
          />
          <!-- ./Desi Limit -->

          <!-- Desi Settings Button -->
          <span
            class="btn btn-primary"
            v-if="!data.is_free"
            v-on:click="showModal = true"
            ><i class="fas fa-edit"></i> Desi Bilgilerini Düzenle</span
          >
          <!-- ./Desi Settings Button -->
        </WeCard>
        <!-- Company Integration -->
        <WeCard v-if="cargoConfig">
          <h5 class="custom-card-title">
            <i class="fas fa-truck mr-2"></i>
            {{ cargoConfig.name }} Entegrasyonu
          </h5>
          <hr />
          <div
            class="d-block mb-3"
            v-for="(element, i) in cargoConfig.elements"
            v-bind:key="i"
          >
            <WeRowInput
              v-if="element.form === 'input'"
              label-class="col-12"
              input-class="col-12"
              v-bind:form="element.form"
              size="sm"
              v-bind:name="element.name"
              v-bind:label="element.title"
              v-bind:placeholder="element.title"
              v-model="element.value"
            />
            <WeSwitch
              v-else-if="element.form == 'switch'"
              :label="element.title"
              v-model="element.value"
            />
          </div>
        </WeCard>
        <!-- Company Integration -->
      </div>
      <!-- ./Right Panel -->
    </div>

    <!-- Desi Modal -->
    <WeModal
      v-show="showModal"
      v-on:close="hideModal"
      title="Desi Bilgilerini Düzenle"
      icon="fas fa-edit"
    >
      <div slot="body">
        <!-- Desi Form -->
        <div class="row align-items-center">
          <div class="col-12 col-lg">
            <div class="form-group">
              <WeInput
                v-bind:filter-number="true"
                v-model="desiForm.first"
                label="İlk Desi"
                name="first-desi"
                ref="firstDesi"
              />
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="form-group">
              <WeInput
                v-bind:filter-number="true"
                v-model="desiForm.last"
                label="Son Desi"
                name="last-desi"
                ref="lastDesi"
              />
            </div>
          </div>
          <div class="col-12 col-lg">
            <div class="form-group">
              <WePriceInput
                v-bind:prepend="data.currency_code.symbol"
                v-model="desiForm.price"
                label="Fiyat"
                name="price"
                ref="price"
                v-on:keyup-enter="addDesi"
              />
            </div>
          </div>
          <div class="col-auto">
            <span class="btn btn-outline-primary" v-on:click="addDesi"
              ><i v-bind:class="actionButton.icon"></i> {{ actionButton.text }}
            </span>
          </div>
        </div>
        <template>
          <hr />
          <WeTable
            style="max-height: 300px; overflow-x: hidden"
            class="custom-scrollbar"
            v-bind:index="false"
            v-bind:limitable="false"
            v-bind:columns="columns"
            v-bind:actions="actions"
            v-bind:data="data.desi"
            v-on:on-action="onAction"
          >
            <template slot="footer">
              <tr>
                <td colspan="3">
                  <div class="offset-7 col-5">
                    <div class="row align-items-center">
                      <div class="col-auto">
                        <label for="overdesi" class="custom-label"
                          >Sonraki her 1 desi ücreti
                          <i
                            class="fas fa-question-circle"
                            v-tooltip.bottom="
                              'Desi üst limiti aşıldığında desi başına uygulanacak ücret'
                            "
                          ></i>
                        </label>
                      </div>
                      <div class="col">
                        <WePriceInput
                          class="mb-0"
                          v-bind:prepend="data.currency_code.symbol"
                          v-model="data.over_desi_price"
                          name="overdesi"
                          ref="overdesi"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </WeTable>
        </template>
        <!-- ./Desi Form -->
      </div>
      <div slot="footer" class="mr-3">
        <WeSubmitButton parent-class="text-center" v-on:submit="onSaveDesi" />
      </div>
    </WeModal>
    <!-- ./Desi Modal -->
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import { getValue } from "vue-currency-input";
import { required } from "vuelidate/lib/validators";
import CargoDeliveryZone from "./views/CargoDeliveryZone/Index.vue";

export default {
  name: "Detail",
  components: {
    CargoDeliveryZone,
  },
  data() {
    return {
      ready: false,
      submit: false,
      showModal: false,
      cargoConfig: null,
      items: [
        {
          logo: null,
          name: "UPS Kargo",
          code: "ups",
          elements: [
            {
              name: "customerNumber",
              value: null,
              form: "input",
              type: "text",
              title: "Müşteri Numarası",
            },
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
          ],
        },
        {
          logo: null,
          name: "PTT Kargo",
          code: "ptt",
          elements: [
            {
              name: "musteriId",
              value: null,
              form: "input",
              type: "text",
              title: "Müşteri Numarası",
              required: true,
            },
            {
              name: "sifre",
              value: null,
              type: "text",
              form: "input",
              title: "Şifre",
              required: true,
            },
            {
              name: "barcodeFirst",
              value: null,
              form: "input",
              type: "text",
              title: "Barkod Başlangıç",
              required: true,
            },
            {
              name: "barcodeLast",
              value: null,
              form: "input",
              type: "text",
              title: "Barkod Bitiş",
              required: true,
            },
            {
              name: "postaCekiHesapNo",
              value: null,
              form: "input",
              type: "text",
              title: "Posta Çeki Hesap Numarası",
            },
          ],
        },
        {
          logo: null,
          name: "Paketle Gitsin",
          code: "paketlegitsin",
          elements: [
            {
              name: "apikey",
              value: null,
              form: "input",
              type: "text",
              title: "API Key",
              required: true,
            },
            {
              name: "sifre",
              value: null,
              type: "text",
              form: "input",
              title: "Şifre",
              required: true,
            },
          ],
        },
        {
          logo: null,
          name: "Yurtiçi Kargo",
          code: "yurtici",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
          ],
        },
        {
          logo: null,
          name: "Aras Kargo",
          code: "aras",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
            {
              name: "customerCode",
              value: null,
              form: "input",
              type: "text",
              title: "Müşteri Kodu",
            },
          ],
        },
        {
          logo: null,
          name: "HepsiJet",
          code: "hepsijet",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
            {
              name: "companyName",
              value: null,
              form: "input",
              type: "text",
              title: "Şirket Adı",
            },
            {
              name: "companyCode",
              value: null,
              form: "input",
              type: "text",
              title: "Şirket Kodu",
            },
            {
              name: "companyAddressId",
              value: null,
              form: "input",
              type: "text",
              title: "Şirket Adres ID",
            },
            {
              name: "currentXDock",
              value: null,
              form: "input",
              type: "text",
              title: "Dock ID",
            },
            {
              name: "isTest",
              value: null,
              form: "switch",
              type: "boolean",
              title: "Test Modu",
            },
          ],
        },
        {
          logo: null,
          name: "Dpd",
          code: "dpd",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
          ],
        },
        {
          logo: null,
          name: "Sendeo",
          code: "sendeo",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
            {
              name: "isTest",
              value: null,
              form: "switch",
              type: "boolean",
              title: "Test Modu",
            },
          ],
        },
        {
          logo: null,
          name: "MNG Kargo",
          code: "mng",
          elements: [
            {
              name: "username",
              value: null,
              type: "text",
              form: "input",
              title: "Kullanıcı Adı",
            },
            {
              name: "password",
              value: null,
              form: "input",
              type: "text",
              title: "Şifre",
            },
          ],
        },
      ],
      desiEdit: false,
      desiPatch: false,
      desiButtonText: "Ekle",
      desiButtonIcon: "fas fa-plus",
      desiForm: {
        temp_id: helper.getRandomInteger(1, 9999),
        name: null,
        first: null,
        last: null,
        price: helper.setPrice(0),
        new: false,
      },
      columns: [
        {
          name: "name",
          th: "Desi Aralığı",
          type: "string",
        },
        {
          name: "price",
          th: "Fiyat",
          type: "currency",
        },
      ],
      actions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary ml-2",
          action: "edit",
        },
        {
          icon: "fas fa-trash",
          class: "btn-outline-danger ml-2",
          action: "remove",
        },
      ],

      // Form Data
      data: {
        name: null,
        cargo_company_id: "-1",
        cargo_insurance: null,
        is_free: false,
        currency_code: null,
        // cargo_payment_id: null,
        direct_payment: {
          min: helper.setPrice(0),
          max: helper.setPrice(0),
          cash: false,
          cash_price: helper.setPrice(0),
          credit_card: false,
          credit_card_price: helper.setPrice(0),
        },
        rates: {
          fixed_price: helper.setPrice(0),
          min_free_shipping: helper.setPrice(0),
          max_desi_limit: 0,
        },
        desi: [],
        orginalDesi: [],
        over_desi_price: helper.setPrice(0),
        delivery_zones: [],
      },
    };
  },
  validations: {
    cargoName: {
      required,
    },
  },
  methods: {
    ...mapActions("cargo", ["load", "create", "update", "getById"]),
    ...mapActions("shared", [
      "getCargoCompanies",
      "getCargoInsurances",
      "getCurrencyList",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    addDesi() {
      const form = helper.clone(this.desiForm);
      const firstInput = this.$refs.firstDesi.$refs.input;
      const lastInput = this.$refs.lastDesi.$refs.input;
      const priceInput = this.$refs.price.$refs.currencyInput;
      form.new = true;

      if (!form.first) {
        firstInput.focus();
      } else if (!form.last) {
        lastInput.focus();
      } else if (!form.price.price) {
        priceInput.focus();
      } else {
        form.name = form.first + " - " + form.last + " Desi";
        form.price = this.desiForm.price.unmask; // Unmasked price
        if (!this.desiEdit) {
          this.data.desi.push(form);
        } else {
          const index = helper.arrayFind(
            this.data.desi,
            "temp_id",
            this.desiForm.temp_id
          );
          this.data.desi[index] = form;

          this.data.desi.sort();
          this.desiEdit = false;
          this.desiPatch = true;
        }
        this.clearDesiForm();
        firstInput.focus();
      }
    },
    clearDesiForm() {
      Object.keys(this.desiForm).forEach((key) => {
        if (key == "temp_id") {
          this.desiForm[key] = helper.getRandomInteger(1, 9999);
        } else if (key == "price") {
          this.desiForm[key] = null;
        } else {
          this.desiForm[key] = null;
        }
      });
      this.desiForm.price = helper.setPrice(0);
    },
    onAction(data) {
      if (data.key == "remove") {
        this.onDelete(data);
      }
      if (data.key == "edit") {
        this.onEdit(data);
      }
    },
    onDelete(data) {
      this.$swal({
        title: "İşlemi Onaylıyor Musunuz ?",
        text: data.row.name + " silinecek.",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "İptal",
        confirmButtonText: "Sil",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          const index = helper.arrayFind(
            this.data.desi,
            "temp_id",
            data.row.temp_id
          );
          if (index >= 0) {
            this.data.desi.splice(index, 1);
          }
        }
      });
    },
    onEdit(data) {
      this.desiEdit = true;
      const priceInput = this.$refs.price.$refs.currencyInput;
      this.desiForm = helper.clone(data.row);
      this.desiForm.price = helper.setPrice(data.row.price);
      priceInput.focus();
      priceInput.blur();
    },
    hideModal() {
      let newCheck;
      let trueLength = 0;
      this.data.desi.forEach((item) => {
        if (item.new) {
          newCheck = true;
          trueLength++;
        }
      });
      if (newCheck && !this.desiPatch) {
        this.$swal({
          title: "İşlemi Onaylıyor Musunuz ?",
          text: "Kaydedilmemiş verileriniz silinecek.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Sil",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.data.desi.forEach((item, i) => {
              if (item.new) {
                this.data.desi.splice(i, trueLength);
              }
            });
            this.data.desi.sort();
            this.showModal = false;
          }
        });
      } else if (this.desiPatch) {
        this.$swal({
          title: "İşlemi Onaylıyor Musunuz ?",
          text: "Yaptığınız değişiklikler geri alınacak.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "İptal",
          confirmButtonText: "Devam et",
        }).then((confirm) => {
          if (confirm.isConfirmed) {
            this.data.desi = helper.clone(this.data.orginalDesi);
            this.desiPatch = false;
            this.showModal = false;
          }
        });
      } else {
        this.showModal = false;
      }
    },
    onSaveDesi() {
      this.data.desi.forEach((item) => {
        item.new = false;
      });
      this.data.orginalDesi = helper.clone(this.data.desi);

      this.showModal = false;
    },
    validateForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    onSaveForm() {
      if (!this.validateForm()) {
        return;
      }
      this.data.desi.map((item) => {
        return delete item.new;
      });

      this.submit = true;
      if (this.$route.params.id) {
        this.onUpdate();
      } else {
        this.onCreate();
      }
    },
    getRequestData() {
      const requestData = helper.clone(this.data);
      requestData.cargoConfig = this.cargoConfigRequest();
      requestData.cash_delivery_setting = this.cashDeliverySetting;

      if (this.cashDeliverySetting) {
        // Unmasked price
        requestData.direct_payment.min = getValue(
          this.$refs.directPaymentMin.$refs.currencyInput
        );
        // Unmasked price
        requestData.direct_payment.max = getValue(
          this.$refs.directPaymentMax.$refs.currencyInput
        );
        // Unmasked price
        requestData.direct_payment.cash_price = getValue(
          this.$refs.directPaymentCash.$refs.currencyInput
        );
        // Unmasked price
        requestData.direct_payment.credit_card_price = getValue(
          this.$refs.directPaymentCreditCard.$refs.currencyInput
        );
      }

      // // Over Desi Price
      // if (requestData.over_desi_price) {
      //   requestData.over_desi_price = getValue(
      //     this.$refs.overdesi.$refs.currencyInput
      //   );
      // }

      return requestData;
    },
    onCreate() {
      this.create({
        form: this.getRequestData(),
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kargo firmaları listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Detaya Git",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                this.$router.push(`detail/${result.data.id}`);
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    onUpdate() {
      this.update({
        id: this.$route.params.id,
        form: this.getRequestData(),
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.$swal({
              title: "İşlem Başarılı",
              text: "Kargo firmaları listesine dönebilir veya bu sayfada kalabilirsiniz.",
              icon: "success",
              showCancelButton: true,
              cancelButtonText: "Sayfada Kal",
              confirmButtonText: "Liste Sayfasına Dön",
            }).then((confirm) => {
              if (confirm.isConfirmed) {
                this.redirectToList();
              } else {
                window.location.reload();
              }
            });
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    redirectToList() {
      this.$router.push("/shipping-companies");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    getCargoById() {
      const id = this.$route.params.id;
      if (id) {
        this.getById({
          id: id,
          onSuccess: (result) => {
            if (result && result.data && result.data.status !== "error") {
              const item = result.data.item;
              this.data = item;
              this.data.orginalDesi = helper.clone(item.desi);
              this.data.desi = this.data.desi.map((item) => {
                item.new = false;
                return item;
              });

              // get cargo configs from array by cargo company id
              this.getCargoConfigs();

              // set cargo config values
              this.setCargoConfigs(item.config);
            }
          },
          onFinish: () => {
            this.ready = true;
          },
        });
      } else {
        this.ready = true;
      }
    },
    getCargoCompany(id) {
      return this.cargoCompanyList.find((item) => item.id === id);
    },
    getCargoConfigs(e) {
      const value = e || this.data.cargo_company_id;
      const cargoCompany = this.getCargoCompany(value);

      if (value !== "-1") {
        this.cargoConfig = this.items.find(
          (item) => item.code === cargoCompany.code
        );
      } else {
        this.cargoConfig = null;
      }
    },
    setCargoConfigs(data) {
      if (data) {
        let source = this.cargoConfig;

        if (source && data && Object.keys(data).length) {
          Object.entries(data).forEach(([configKey, configValue]) => {
            let sourceElement = source.elements.findIndex(
              (elem) => elem.name === configKey
            );
            if (sourceElement !== -1) {
              source.elements[sourceElement].value = configValue;
            }
          });
        }
      }
    },
    cargoConfigRequest() {
      if (this.cargoConfig && this.data.cargo_company_id !== "-1") {
        let result = {};

        if (this.cargoConfig && this.cargoConfig.elements) {
          this.cargoConfig.elements.map((element) => {
            result[element.name] = element.value;
          });
        }
        return result;
      }
    },
  },
  computed: {
    ...mapState(["cargo", "shared", "session"]),
    getTitle() {
      return this.$route.params.id
        ? "KARGO FİRMASI DÜZENLE"
        : "KARGO FİRMASI EKLE";
    },
    cashDeliverySetting() {
      return (
        this.session.config["site.cash_on_delivery"] === "1" ||
        this.session.config["site.cash_on_delivery"] === 1
      );
    },
    cargoCompanyList() {
      return [...this.shared.cargoCompanies.list, { id: "-1", name: "Diğer" }];
    },
    checkPermission() {
      return permission.check("cargo", "u");
    },
    cargoName: {
      get() {
        return this.data.name;
      },
      set(value) {
        this.data.name = value;
      },
    },
    actionButton() {
      const result = {
        icon: "fas fa-plus",
        text: "Ekle",
      };
      if (this.desiEdit) {
        result.icon = "fas fa-edit";
        result.text = "Güncelle";
      }
      return result;
    },
  },
  mounted() {
    this.getCurrencyList({
      onSuccess: (result) => {
        if (!this.$route.params.id) {
          this.data.currency_code = this.shared.currencyCodes.currencies[0];
        }
      },
      onFinish: () => {
        this.getCargoCompanies({
          onFinish: () => {
            this.getCargoInsurances({
              onFinish: () => {
                this.getCargoById();
              },
            });
          },
        });
      },
    });
  },
};
</script>
